<template>
    <div class="home">
        <nav class="navbar">
	  		<div class="container">
	  			<img src="@/assets/images/elab-logo.png" class="Ennovate Lab's logo">
	  			<a href="bit.ly/elablearning-masters" target="blank" class="btn">Get Started Here</a>
	  		</div>
	  	</nav>

        <div class="hero">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 hero__text">
                        <h2>Transition into Tech Without Hassle</h2>
                        <p>Acquire a premium tech skill that employers are looking for and get industry experience fast.</p>
                        <a href="bit.ly/elablearning-masters" target="blank" class="btn">Get Started Here</a>
                    </div>
                    <div class="col-md-6 hero__img">
                        <img src="@/assets/images/elab-hero-image.png" class="Ennovate Lab's Learning Hero Image">
                    </div>
                </div>
            </div>
        </div>

        <div class="learning">
            <div class="container">
                <div class="row">
                    <div class="col-12 learning__text">
                        <h3 class="learning__text--header">eLab Learning is preparing Africa’s budding workforce for the future of work!</h3>
                        <p class="learning__text--subtext"> Learn tech skills that matter in the overall lifecycle of a digital product. Skills like data science, marketing, development, storyselling and design are required by employers in today’s digital economy. </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="school">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 school__head">
                        <h2>Take A School Tour...</h2>
                    </div> 
                     
                    <div class="col-md-4 school__tour">
                        <div class="card mb-3">
                            <img src="@/assets/images/elab_data.jpg" class="card-img-top" alt="elab learning">
                            <div class="card-body">
                                <h5 class="card-title">Data School</h5>
                                <a href="https://drive.google.com/file/d/1GnGWPDFCsYeArv0Qdxd2InsIThHqANGy/view?usp=sharing" target="blank">Get Brochure</a>
                            </div>
                        </div>
                    </div>
         
                    <div class="col-md-4 school__tour">
                        <div class="card mb-3">
                            <img src="@/assets/images/elab_storytelling.jpg" class="card-img-top" alt="elab learning">
                            <div class="card-body">
                                <h5 class="card-title">StorySelling School</h5>
                                <a href="https://drive.google.com/file/d/1a7f5rDqaxilWZY9Ycw9Gswb55reX-qQ_/view?usp=sharing" target="blank">Get Brochure</a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-4 school__tour">
                        <div class="card mb-3">
                            <img src="@/assets/images/elab_design.jpg" class="card-img-top" alt="elab learning">
                            <div class="card-body">
                                <h5 class="card-title">Design School</h5>
                                <!-- <a href="https://drive.google.com/file/d/1X6IwDMcJbXJShut-kXUiR4NZp4SIZj3y/view?usp=sharing" target="blank">Get Brochure</a> -->
                                <a href="#" class="coming-soon">Coming soon</a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-4 school__tour">
                        <div class="card mb-3">
                            <img src="@/assets/images/elab_dev.jpg" class="card-img-top" alt="elab learning">
                            <div class="card-body">
                                <h5 class="card-title">Dev School</h5>
                                <!-- <a href="https://drive.google.com/file/d/1mMOXRW9nM5P_teVmkJ7NYZ1mUxXCmkMy/view?usp=sharing" target="blank">Get Brochure</a> -->
                                <a href="#" class="coming-soon">Coming soon</a>
                            </div>
                        </div>
                    </div>
                   
                    <div class="col-md-4 school__tour">
                        <div class="card mb-3">
                            <img src="@/assets/images/elab_digital.jpg" class="card-img-top" alt="elab learning">
                            <div class="card-body">
                                <h5 class="card-title">Digital Marketing School</h5>
                                <a href="#" class="coming-soon">Coming soon</a>
                            </div>
                        </div>
                    </div>

                    <div class="school__btn">
                        <a href="https://bit.ly/elablearningmasters" target="blank" class="btn">Register for a Free Masterclass</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="offerings">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 offerings__head">
                        <h2>Start Learning Now</h2>
                    </div>
                    <div class="col-md-12 offerings__list">
                        <div class="row">
                            <div class="col-md-4 offerings__list--card">
                                <div class="card">
                                    <span class="fas fa-check"></span>
                                    <p>100% Online Live Virtual Delivery model</p>
                                </div>
                            </div>
                            <div class="col-md-4 offerings__list--card">
                                <div class="card">
                                    <span class="fas fa-check"></span>
                                    <p>100% Industry Expert-Led Sessions</p>
                                </div>
                            </div>
                            <div class="col-md-4 offerings__list--card">
                                <div class="card">
                                    <span class="fas fa-check"></span>
                                    <p>Globally recognised courses</p>
                                </div>
                            </div>
                            <div class="col-md-4 offerings__list--card">
                                <div class="card">
                                    <span class="fas fa-check"></span>
                                    <p>Affordable price points</p>
                                </div>
                            </div>
                            <div class="col-md-4 offerings__list--card">
                                <div class="card">
                                    <span class="fas fa-check"></span>
                                    <p>Real-world tech use-cases</p>
                                </div>
                            </div>
                            <div class="col-md-4 offerings__list--card">
                                <div class="card">
                                    <span class="fas fa-check"></span>
                                    <p>An active community of fellow learners</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div class="contact">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h5>Contact Us</h5>
                        <h3>learning@ennovatelab.com</h3>

                        <p class="contact__numbers">
                            <small> +234 805 176 2471,</small>
                            <small> +234 903 848 3915</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="alumni">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 alumni__head">
                        <h2>Our Alumni</h2>
                    </div> 

                    <div class="col-lg-4 alumni__feedback">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="alumni__feedback--img">
                                        <img alt="Image placeholder" src="@/assets/images/elab_debola.png" class="">
                                    </div>
                                    <div class="alumni__feedback--info">
                                        <h5 class="h6 mb-0">Debola</h5>
                                        <small class="d-block text-muted">UI/UX Designer</small>
                                    </div>
                                </div>
                                <p class="mt-4 lh-180">I joined as a novice but now I can say there is a touch of excellence in my designs and this happened within a short time frame (Less than 9 months). Thanks to my awesome team lead and the ennovators at large.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 alumni__feedback">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="alumni__feedback--img">
                                        <img alt="Image placeholder" src="@/assets/images/elab_josh.png" class="">
                                    </div>
                                    <div class="alumni__feedback--info">
                                        <h5 class="h6 mb-0">Joshua</h5>
                                        <small class="d-block text-muted">Full Stack Developer</small>
                                    </div>
                                </div>
                                <p class="mt-4 lh-180">The environment is a conducive place to learn. From an inexperienced tech enthusiast in 2018, I became a front end developer and an intern in 2019 working directly with the Summer Code Week team of ennovate lab in 2019. At the moment, I am a full stack developer. Thanks to ennovate lab. </p>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 alumni__feedback">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="alumni__feedback--img">
                                        <img alt="Image placeholder" src="@/assets/images/elab_favour.png" class="">
                                    </div>
                                    <div class="alumni__feedback--info">
                                        <h5 class="h6 mb-0">Favour</h5>
                                        <small class="d-block text-muted">Content Writer &amp; Digital Marketing</small>
                                    </div>
                                </div>
                                <p class="mt-4 lh-180">Before taking their course on writing. I had no clue what it entailed. I was amateurish at best. However, after taking their course, I saw a radical improvement in my content and copywriting. Their courses are very applicable, and whatever they teach can be easily mastered, because they made it so simple to learn and understand. I went on to get a job as a content strategist in my dream place of work. </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="faqs">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 faqs__head">
                        <h2>FAQs</h2>
                    </div>  
                    <div class="col-md-12 faqs__questions">
                        <div class="general">
                            <h3 class="header">General</h3>
                            <div class="accordion" id="general">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#general-one">
                                            What is eLab Learning?
                                        </button>
                                    </h2>
                                    <div id="general-one" class="accordion-collapse collapse show" data-bs-parent="#general">
                                        <div class="accordion-body">
                                            eLab Learning is a technology education program that empowers fellows with premium tech skills needed in the overall product lifecycle; skills which include, Software Application Development, Data Science, UI/UX Design, Storyselling and Digital Marketing. The program equips technology professionals with the skills that employers demand.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general-two"  >
                                            Who can Enrol?
                                        </button>
                                    </h2>
                                    <div id="general-two" class="accordion-collapse collapse" data-bs-parent="#general">
                                        <div class="accordion-body">
                                            Students, Corpers and entry-level professionals and core professionals interested in transitioning into a technology role.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general-three"  >
                                            Do I need to have any level of skill before I enrol in the school?
                                        </button>
                                    </h2>
                                    <div id="general-three" class="accordion-collapse collapse" data-bs-parent="#general">
                                        <div class="accordion-body">
                                            No. You don’t need any skill to enrol in any of the learning programs.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general-four"  >
                                            Do I need a PC to enrol in this learning program?
                                        </button>
                                    </h2>
                                    <div id="general-four" class="accordion-collapse collapse" data-bs-parent="#general">
                                        <div class="accordion-body">
                                            Yes. You need one for optimal performance and participation.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pricing">
                            <h3 class="header">Pricing and Payment</h3>
                            <div class="accordion" id="pricing">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#pricing-one">
                                            How much will it cost me to enrol in the school?
                                        </button>
                                    </h2>
                                    <div id="pricing-one" class="accordion-collapse collapse show" data-bs-parent="#pricing">
                                        <div class="accordion-body">
                                            Please contact support for the latest pricing.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pricing-two"  >
                                            Will I be getting a discount if I am taking more than one course?
                                        </button>
                                    </h2>
                                    <div id="pricing-two" class="accordion-collapse collapse" data-bs-parent="#pricing">
                                        <div class="accordion-body">
                                            No. Each learning program has its own standard fee. 
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pricing-four"  >
                                            Will I be getting a discount if I refer my friends?
                                        </button>
                                    </h2>
                                    <div id="pricing-four" class="accordion-collapse collapse" data-bs-parent="#pricing">
                                        <div class="accordion-body">
                                            Referral discount is not available. However, you have the privilege to learn and grow with your friends when you refer them to our learning program.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="courses">
                            <h3 class="header">Courses</h3>
                            <div class="accordion" id="courses">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#courses-one">
                                            Can I take more than one course at a time?                                        
                                        </button>
                                    </h2>
                                    <div id="courses-one" class="accordion-collapse collapse show" data-bs-parent="#courses">
                                        <div class="accordion-body">
                                            The courses will run concurrently, which will not give you ample time to attend different learning programs at the same time; but you can move to another course soon as you are done.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#courses-three"  >
                                            What is the schedule for the classes?
                                        </button>
                                    </h2>
                                    <div id="courses-three" class="accordion-collapse collapse" data-bs-parent="#courses">
                                        <div class="accordion-body">
                                            The class schedule will be on the program’s brochure. You can download the brochure for the respective schools on our website.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#courses-four"  >
                                            What happens if and when I miss a class?
                                        </button>
                                    </h2>
                                    <div id="courses-four" class="accordion-collapse collapse" data-bs-parent="#courses">
                                        <div class="accordion-body">
                                            You get a rewatch link sent to you after each class whether or not you are present.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="community">
                            <h3 class="header">Community</h3>
                            <div class="accordion" id="courses">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#courses-one">
                                            How do I interact with my faculty lead?                                     
                                        </button>
                                    </h2>
                                    <div id="courses-one" class="accordion-collapse collapse show" data-bs-parent="#courses">
                                        <div class="accordion-body">
                                            You will be added to a community where communication can freely flow from you to your program’s faculty lead and vice versa.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#courses-two"  >
                                            Is there interaction with other students?
                                        </button>
                                    </h2>
                                    <div id="courses-two" class="accordion-collapse collapse" data-bs-parent="#courses">
                                        <div class="accordion-body">
                                            Yes. All students in the same learning program will have access to the same community where they can all share and learn together.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="program">
                            <h3 class="header">After Program</h3>
                            <div class="accordion" id="program">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#program-one">
                                            Will I get certified after my program has ended?                                       
                                        </button>
                                    </h2>
                                    <div id="program-one" class="accordion-collapse collapse show" data-bs-parent="#program">
                                        <div class="accordion-body">
                                            Yes. Certificates will be provided after the completion of your learning program, and submission of your capstone project.  
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#program-two"  >
                                            Will I be matched with employers after the completion of my program?
                                        </button>
                                    </h2>
                                    <div id="program-two" class="accordion-collapse collapse" data-bs-parent="#program">
                                        <div class="accordion-body">
                                            Yes. We will gladly recommend you to employers whenever a job opens up.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#program-three"  >
                                            Is there an internship opportunity for when the program is over?

                                        </button>
                                    </h2>
                                    <div id="program-three" class="accordion-collapse collapse" data-bs-parent="#program">
                                        <div class="accordion-body">
                                            Yes. After the completion of the learning program, you will be privileged to work with the amazing team at Ennovate Lab and or similar brands like ours.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Home",

        data(){
            return{

            }
        },

        created(){

        },

        methods: {

        }
    }
</script>

<style lang="scss">

    $elab-primary : #FFCC03;
    $elab-black : #04000D;
    $elab-white : #fff;
    $elab-gray : #272C33;
    $elab-gray2 : #191c21;

    .home {
        .navbar {

            background: $elab-black;

            img {
                width: 60px;
            }

            .btn {
                
                padding: .75rem 1rem;
                font-weight: bold;

                background: $elab-primary;
                color: $elab-black;
                border: 1px solid $elab-primary;

                &:hover {
                    border: 1px solid #fff;
                    background: $elab-white;
                }
            }
        }

        .hero {

            background: $elab-black;
            color: $elab-white;
            align-items: center;
            padding: 7rem 1rem;

            &__text {

                align-self: center;

                h2 {
                    font-size: 3rem;
                    font-weight: bold;
                }

                p {
                    font-size: 1rem;
                    margin-bottom: 2rem;
                }

                .btn {
                    padding: .75rem 1rem;
                    font-weight: bold;

                    background: $elab-primary;
                    color: $elab-black;
                    border: 1px solid $elab-primary;

                    &:hover {
                        border: 1px solid #fff;
                        background: $elab-white;
                    }
                }
            }

            &__img {
                img {
                    width: 100%;
                }
            }
        }

        .learning {

            color: $elab-white;
            background: $elab-black;
            padding: 5rem 1rem;

            .container {
                background: linear-gradient( rgba(0,0,0, 0.6), rgba(0,0,0, 0.6)), url("../assets/images/elab-learning.jpg");
                background-size: cover;
                height: 70vh;
                display: flex;
                align-items: flex-end;
            }

            &__text{
                text-align: center;
                padding: 3rem 12rem;
                
                &--header{
                    // font-size: ;
                    font-weight: bold;
                    
                    margin-bottom: 2rem;
                }
                &--subtext{
                    font-size: 1.2rem;
                }
            }
        }

        .school {

            background: $elab-gray2;
            padding: 5rem 7rem;

            &__head {
                text-align: center;
                margin-bottom: 3rem;

                h2 {
                    font-size: 1.75rem;
                    font-weight: bold;
                    color: $elab-white;
                }
            }

            &__tour {
                text-align: center;

                .card {
                    background: $elab-gray;
                    color: $elab-white;

                    &-title{
                        font-weight: bold;
                    }

                    a {
                        color:$elab-primary;

                        &.coming-soon {
                            cursor: not-allowed;
                        }
                    }
                }
            }

            &__btn {

                text-align: center;
                margin-top: 3rem;

                .btn {
                    text-align: center;
                    padding: .75rem 1rem;
                    font-weight: bold;
                    border: 1px solid #fff;
                    background: $elab-white;
                    color: $elab-black;

                    &:hover {
                        background: $elab-primary;
                        border: 1px solid $elab-primary;
                    }
                }
            }
        }

        .offerings {
            color: $elab-black;
            background: $elab-black;
            padding: 5rem 1rem;

            .container {
                background: $elab-primary;
                border-radius: 1rem;
                padding: 3rem 3rem;
            }

            &__head {
                text-align: center;
                margin-bottom: 3rem;
                h2 {
                    font-size: 1.75rem;
                    font-weight: bold;
                }
            }

            &__list {
                &--card {
                    .card {
                        background: transparent;
                        flex-direction: row;
                        padding: 1rem;
                        border: none;
                        margin-bottom: 1rem;

                        .fas {
                            font-size: 1.2rem;
                            display: inline-block;
                            margin-right: 1.2rem;
                            margin-top: .25rem;
                        }
                    
                        p {
                            margin-bottom: 0;
                            font-size: 1rem;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .contact {
            color: $elab-white;
            background: $elab-black;
            padding: 3rem 1rem;

            .container {
                background: linear-gradient( rgba(0,0,0, 0.6), rgba(0,0,0, 0.6)), url("../assets/images/elab_bolt.png") center;
                background-size: cover;
                height: 40vh;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                h5 {
                    font-weight: bold;
                    margin-bottom: 3rem;
                    font-size: 1.5rem;
                }

                h3 {
                    font-size: 3rem;
                    margin-bottom: 1rem;
                    font-weight: bold;
                }

                .contact__numbers {
                    margin-bottom: 5rem;

                    small {
                        font-size: 1rem;
                    }
                }

            }
        }

        .alumni {
            color: $elab-white;
            background: $elab-gray2;
            padding: 5rem 7rem;

            &__head {
                text-align: center;
                margin-bottom: 3rem;
                h2 {
                    font-size: 1.75rem;
                    font-weight: bold;
                }
            }

            &__feedback {
                color: $elab-white;

                .card {
                    background: $elab-black;
                }

                &--img {
                    margin-right: 1.2rem;

                    img {
                        width: 70px;
                        display: inline-block;
                    }
                }
            }
        }

        .faqs {
            color: $elab-white;
            background: $elab-black;
            padding: 5rem 7rem;

            &__head {
                text-align: center;
                margin-bottom: 3rem;
                h2 {
                    font-size: 1.75rem;
                    font-weight: bold;
                }
            }

            &__questions {

                .header {
                    margin-bottom: 1rem;
                    font-weight: lighter;
                    font-size: 1.2rem;
                }

                .general, .pricing, .courses, .community, .program {
                    margin-bottom: 5rem;
                }

                .accordion {
                    
                    &-item {
                        margin-bottom: 1rem;
                        border: none;
                    }

                    &-header {
                        border: none;

                        .accordion-button {
                            border: none;
                            box-shadow: none;
                            background: $elab-gray;
                            color: $elab-white;
                            font-size: 1.2rem;
                            font-weight: bold;
                            padding: 1.5rem 1.5rem;
                            border-top-left-radius: .5rem;
                            border-top-right-radius: .5rem;

                            &:hover {
                                color: $elab-primary !important;
                            }

                            &:after {
                                content: "";
                                width: 1.25rem;
                                height: 1.25rem;
                                margin-left: auto;
                                color: $elab-white !important;
                                background-size: 1.25rem;
                                transition: transform .2s ease-in-out;
                            }
                        }
                    }

                    &-body {
                        background: $elab-gray;
                        border-bottom-left-radius: .5rem;
                        border-bottom-right-radius: .5rem;
                    }

                    
                }
            }
        }

        .social {
            background: $elab-black;
            padding: 1rem;
            text-align: center;
            border-top: 1px solid $elab-gray;

            &__links {

                a {
                    margin-right: 2rem;
                    color: $elab-white;
                    font-size: 2rem;
                }

            }
        }
    }

    @media screen and (max-width: 600px) {
        .home {
            .navbar {
                img {
                    width: 50px;
                }

                .btn {                    
                    padding: .4rem .7rem;
                }
            }

            .hero {

                padding: 5rem 1rem;

                &__text {

                    align-self: center;

                    h2 {
                        font-size: 2.5rem;
                    }

                    p {
                        font-size: 1rem;
                        margin-bottom: 2rem;
                    }

                    .btn {
                        padding: .65rem .75rem;
                    }
                }

                &__img {
                    display: none;
                }
            }

            .learning {

                padding: 1rem 1rem;

                .container {
                    background: linear-gradient( rgba(0,0,0, 0.8), rgba(0,0,0, 0.8)), url("../assets/images/elab-learning.jpg") center;
                    height: auto;
                }

                &__text{
                    text-align: center;
                    padding: 3rem 1rem;
                    
                    &--header{
                        margin-bottom: 2rem;
                        font-size: 1.2rem;
                    }
                    &--subtext{
                        font-size: 1rem;
                    }
                }
            }

            .school {

                padding: 5rem 1rem;

                &__head {
                    margin-bottom: 3rem;

                    h2 {
                        font-size: 1.5rem;
                    }
                }

                &__tour {
                    margin-bottom: 2rem;
                }

                &__btn {

                    margin-top: 0rem;

                    .btn {
                        padding: .75rem 1rem;
                    }
                }
            }

            .offerings {
                padding: 3rem 1rem;

                .container {
                    padding: 3rem 1rem;
                }

                &__head {
                    margin-bottom: 2rem;

                    h2 {
                        font-size: 1.5rem;
                    }
                }

                &__list {
                    &--card {
                        .card {
                            flex-direction: row;
                            padding: 1rem;
                            margin-bottom: 0rem;

                            .fas {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }

            .contact {
                padding: 3rem 1rem;

                .container {
                    background: linear-gradient( rgba(0,0,0, 0.6), rgba(0,0,0, 0.6)), url("../assets/images/elab_bolt.png") center;
                    height: 40vh;

                    h5 {
                        margin-bottom: 3rem;
                        font-size: 1rem;
                    }

                    h3 {
                        font-size: 1.5rem;
                        margin-bottom: 1rem;
                    }

                    small {
                        font-size: 1rem;
                    }
                }
            }

            .alumni {
                padding: 3rem 1rem;

                &__head {
                    text-align: center;
                    margin-bottom: 3rem;
                    h2 {
                        font-size: 1.75rem;
                        font-weight: bold;
                    }
                }

                &__feedback {
                    margin-bottom: 2rem;

                    &--img {
                        margin-right: 1.2rem;

                        img {
                            width: 70px;
                        }
                    }
                }
            }

            .faqs {
                padding: 3rem 1rem;

                &__head {
                    margin-bottom: 3rem;
                    h2 {
                        font-size: 1.5rem;
                    }
                }

                &__questions {

                    .header {
                        margin-bottom: 1rem;
                        font-size: 1rem;
                    }

                    .general, .pricing, .courses, .community, .program {
                        margin-bottom: 3rem;
                    }

                    .accordion {
                        
                        &-item {
                            margin-bottom: 1rem;
                            border: none;
                        }

                        &-header {
                            border: none;

                            .accordion-button {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }

            .social {

                &__links {

                    a {
                        margin-right: 2rem;
                        font-size: 1.2rem;
                    }

                }
            }
        }
    }
</style>