<template>
    <div id="app">
        <Home />
    </div>
</template>

<script>
import Home from './components/Home.vue'

export default {
    name: 'App',
    components: {
        Home
    }
}
</script>

<style lang="scss">
    #app {
        font-family: 'Poppins', sans-serif;
    }
</style>
